import axios from "axios";
import * as React from "react";
import styled from "styled-components";
import MailChimp from "./Mailchimp";

import arrow from "./arrow.svg";
import { isAbsolute } from "path";

const StyledFormNotify = styled.div`
    position: relative;
    min-width: 300px;
    max-width: 420px;
    margin: 10px auto;    
    padding: 0 25px;
    box-sizing: border-box;

    &.has-error {
      background-color: #da8c8c;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    input {
      flex: 1;
      border: 1px solid #FFF;
      background: rgba(255,255,255,0.8);
      line-height: 40px;
      outline: none;
      border-radius: 10px;
      padding: 5px 15px;
      display: block;
    }

    button {
      display: block;
      background-color: #E9C46A;
      color: #FFF;
      align-items: center;
      border: 1px solid #FFF;
      border-radius: 10px;
      padding: 5px 15px;
      font-weight: bold;
      line-height: 40px;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 20px;
      
      :hover {
        background-color: #ebbe4e;
      }
      
      img {
        margin-left: 10px;
        display: inline-block;
        margin-top: 5px;
      }
    }
  `;

const FormNotify = ({
  backgroundColor} : {backgroundColor?: string }): React.ReactElement => {

  const url = "https://getTrolly.us1.list-manage.com/subscribe/post?u=398482454516a9bb9108d429f&amp;id=56afce6061";

  const [email, setEmail] = React.useState<string>("");
  const [hasError, setError] = React.useState<boolean>(false);

  return (
    <StyledFormNotify className={hasError ? "has-error" : undefined}>
      <MailChimp
        action={url}
        fields={[
          {
            name: "EMAIL",
            placeholder: "Email",
            type: "email",
            required: true
          }
        ]}

        messages = {
          {
            sending: "Loading",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "You are already signed up.",
            button: "Get Early Access"
          }
        }
        styles={{
          sendingMsg: {
            color: "#FFF",
            margin: 0,
            background: backgroundColor || "#31aab7",
            fontFamily: "'Montserrat'",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: "center",
            paddingTop: 15,
          },
          successMsg: {
            margin: 0,
            color: "#FFF",
            background: backgroundColor || "#31aab7",
            fontFamily: "'Montserrat'",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: "center",
            paddingTop: 15,
          },
          duplicateMsg: {
            margin: 0,
            color: "#FFF",
            background: backgroundColor || "#31aab7",
            fontFamily: "'Montserrat'",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: "center",
            paddingTop: 15,
          },
          errorMsg: {
            color: "#ffadad",
            position: "absolute",
            top: -30,
            left: 0,
            right: 0,
            textAlign: "center",
          }
        }}
        />
      {/* <form onSubmit={onSubmit}
      onInvalid={() => setError(true)}
      >
      <input type="email" placeholder="Email Address"
      value={email} onChange={(e)=> {setEmail(e.target.value); setError(false)}} required />
      <button type="submit">
        Get Invite
        <img src={arrow} width={15} />
      </button>
      </form> */}
    </StyledFormNotify>
  );
};

export default FormNotify;