import * as React from "react";
import jsonp from "jsonp";
import styled from "styled-components";

interface InputProps {
    name: string;
    placeholder: string;
    type: string;
    required: boolean;
}

interface MailchimpProps {
    action: string,
    messages: {
        sending: string,
        success: string,
        error: string,
        empty: string,
        duplicate: string,
        button: string
    },
    fields: InputProps[],
    styles?: {
        sendingMsg: React.CSSProperties,
        successMsg: React.CSSProperties,
        duplicateMsg: React.CSSProperties,
        errorMsg: React.CSSProperties,
    },
    className?: string,
    buttonClassName?: string
}


const StyledForm = styled.form`
    .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
    }
`;


const MailChimp = ({
    action = "post",
    fields = [],
    messages = {
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An unexpected internal error has occurred.",
        empty: "You must write an e-mail.",
        duplicate: "Too many subscribe attempts for this email address",
        button: "Subscribe!"
    },
    className = "",
    buttonClassName = "",
    styles = {
        sendingMsg: {
        color: "#0652DD"
        },
        successMsg: {
        color: "#009432"
        },
        duplicateMsg: {
        color: "#EE5A24"
        },
        errorMsg: {
        color: "#ED4C67"
        }
    }
}: MailchimpProps): React.ReactElement<MailchimpProps> =>  {

    const [status, setStatus] = React.useState("");
    const [fieldsValues, setFieldValues] = React.useState<Record<string, string>>({});

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        // Prevent default actions like browser form post.
        e.preventDefault();
        
        // Map all fields as parameter string.
        const values = fields.map(field => {
            return `${field.name}=${encodeURIComponent(fieldsValues[field.name])}`;
        }).join("&");

        // Create Path & URL
        const path = `${action}&${values}`;
        const url = path.replace("/post?", "/post-json?");
        const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;

        // Validate Email Address;
        const email = fieldsValues["EMAIL"];
        (!regex.test(email)) ? setStatus("empty") : sendData(url);
    }

    const sendData = (url: string) => {
        setStatus("sending");
        jsonp(url, { param: "c" }, (err, data) => {
            if (data.msg.includes("already subscribed")) {
            setStatus("duplicate");
            } else if (err) {
            setStatus("error");
            } else if (data.result !== "success") {
            setStatus("error");
            } else {
            setStatus("success");
            }
        });
    }

    const updateForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        fieldsValues[e.target.name] = e.target.value
        setFieldValues(fieldsValues);
    }

    return (
        <StyledForm onSubmit={handleSubmit} method="POST" className={className}>
        {fields.map(input =>
            <input
            {...input}
            key={input.name}
            onChange={updateForm}
            defaultValue={fieldsValues[input.name]}
            />
        )}
        <button
            disabled={status === "sending" || status === "success"}
            type="submit"
            className={buttonClassName}>
            {messages.button}
        </button>
        <div className='msg-alert'>
            {status === "sending" && <p style={styles.sendingMsg}>
            <div className="lds-ripple"><div></div><div></div></div>
            <br />
                {messages.sending}
            </p>}
            {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
            {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
            {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
            {status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
        </div>
        </StyledForm>
    );
}

export default MailChimp;
