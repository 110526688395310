import * as React from "react";
import "./App.css";
import Header from "./components/header/header";
import styled from "styled-components";
import { Fade, Slide, Roll } from "react-awesome-reveal";

import FormNotify from "./components/form-notify/form-notify";
import swipeImg from "./assets/swipe.svg";
import peopleImg from "./assets/people-having-fun.jpg";
import mapBgImg from "./assets/map-bg.jpg";
import goodPuraEarthDealImg from "./assets/good-puraearth-deal.png";
import goodIconImg from "./assets/good-icon.png";
import truckImg from "./assets/truck.svg";
import golfIconImg from "./assets/golf-icon.svg";
import feedImg from "./assets/feed.png"
import arrowLeftImg from "./assets/arrow-left.svg";
import arrowRightImg from "./assets/arrow-right.svg";
import dealsImg from "./assets/deals.png";
import mapImg from "./assets/map.png";
import communityImg from "./assets/community.png";

import appStoreImg from "./assets/platform/app-store.png";
import playStoreImg from "./assets/platform/play-store.png";
import { useInterval } from "react-interval-hook";

const StyledApp = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  color: #FFF;

  @media only screen and (max-width: 600px) {
    /* padding-left: 25px;
    padding-right: 25px; */
  }

  h1, h2, h3 {
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    }

  .header-container {
    background-color: #31AAB7;

    .form-notify {
      margin-bottom: 10px !important;
    }
    
    p {
      padding: 0 15px !important;

      &.sub-title {
        margin: 0;
        padding: 0;
        font-size: 0.8em;
        text-align: center;
        margin: auto;
      }
    }

    .swipe {
      position: relative;
      width: 100%;
      height: 100px;
      margin: auto;

      img {
        width: 100%;
        transform: translateY(-10%);
        height: 200px;
      }
    }

  h1 {
      text-align: center;
      /* text-transform: uppercase; */
      /* letter-spacing: 3px; */
      font-size: 1.3em;
      max-width: 480px;
      margin: auto;
      padding: 10px;
      line-height: 1.8em;

      strong {
        display: inline-block;
        padding: 0 5px;
        line-height: 1.5em;
        background-color: #264653;
        transform: rotate(-5deg);
      }

      strong.a {
        color: #E9C46A;
      }
      strong.b {
        color: #F4A261;
        
      }
      strong.c {
        color: #E76F51;
      }
      strong.d {
        color: #FFF;
      }
    }

    p {
        margin: auto;
        display: block;
        max-width: 420px;
        line-height: 1.8em;
        font-size: 1em;
        padding: 5px;
        text-align: center;
        box-sizing: border-box;
    }

    .form-notify {
      margin: 35px auto;
    }

    .grouped {
      text-align: center;
      
      img {
        vertical-align: middle;
        margin: 5px;
      }
    }
  }

  h2, h3 {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 1.5em;
      margin-top: 0px;

      strong {
        /* display: inline-block;
        margin-top: 15px;
        color: #264653;
        border-bottom: 2px solid #264653; */
      }
    }
  .body-content {
    background: url(${peopleImg});
    background-size: cover;
    background-position: bottom;
  

    .party {
      padding: 80px 20px 80px 20px;
      padding-top: 120px;
      background-color: rgba(233, 196, 106, 0.8);
      box-shadow: inset 0 -160px 20px -20px #000000;
      /* background-color: rgba(49, 178, 183, 0.8); */
      /* background-color: rgba(231, 111, 81, 0.8); */
      margin-bottom: -150px;
    }

    h2 {
      color: #264653;

      strong {
        background-color: #264653;
        color: #FFF;
        padding: 2px 8px;
        transform: rotate(-5deg);
      }
    }

    .app-frame {
      position: relative;
      top: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      width: 250px;
      height: 510px;
      background-color: #FFF;
      margin: auto;

      background: url(${feedImg});
      background-size: cover;

    }
  }



  .engage{
      padding: 120px 20px 40px 20px;
      text-align: center;
      background-color: #E76F51;
      color: #FFF;
      box-shadow: inset 0 -15px 20px -20px #000000;

      .engage-item {
        display: flex;
        max-width: 420px;
        margin: 0 auto !important;
        text-align: center;
        padding: 10px;
        padding-bottom: 40px;
        flex-direction: row;

        h3 {
          margin-bottom: 0.5em;
        }

        .engage-copy {
          display: flex;
          flex-direction: column;

          p {
            min-height: 80px;
          }
        }

        img {
          margin: auto;
          margin-top: 25px;
          width: 100%;
          max-width: 350px;
          display: inline-block;
          vertical-align: middle;
          justify-self: end;
          align-self: flex-end;

        }
      }

      @media (min-width: 768px) {
        display: flex;
        justify-content: center;

        .engage-item {
          text-align: center;
          max-width: 300px;
          align-items: flex-start;
        }
      }

      p {
        margin: auto;
        max-width: 420px;
        /* color: #264653; */
        line-height: 1.5em;
      }

      .trolly-icons {
        padding-top: 25px;
      }

    }

    .map-slides-container {

      .map-slides {
        justify-content: start;
        padding-top: 60px;
        padding-bottom: 60px;
      }

      .map-slides > div {
          max-width: 420px;
          margin: 0 auto !important;
        }

      @media (min-width: 768px) {
        display: flex;

        
      }
    }

    .map {
      height: 450px;
      background: url(${mapBgImg});
      background-position: right center;
      background-size: cover;
      position: relative;
      box-shadow: inset 0 -15px 20px -20px #000000;
      flex: 1;

      .map-items {
        background-color: rgba(42, 157, 143, 0.4);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

      .item {
        z-index: 100;
        border-radius: 1em;
        width: 1em;
        height: 1em;
        position: absolute;
        border: 0.5em solid #264653;
        background-color: #FFF;
        position: absolute;
        top: 1em;
        left: 1em;

        &.item-pin {
          width: 1em;
          height: 1em;
        }

        &.itm-content {
          display: flex;
          padding: 0.5em;
          color: #264653;

          .copy {
            margin-left: 1em;
          }

          .title {
            font-size: 0.9em;
            margin-bottom: 0.6em;
            font-weight: bold;
            text-transform: uppercase;
          }
          
          .description {
            font-size: 0.8em;
          }
        }
      }

      }
    }

    .i1-selected {
      .i1 {
        border-color: #E9C46A !important;
      }

      .p1 {
        background-color: #E9C46A !important;
      }

      .slide-1 {
        display: block !important;
      }
    }

    .item, .pin {
      transition: 500ms all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .i2-selected {
      .i2 {
        border-color: #E9C46A !important;
      }

      .p2 {
        background-color: #E9C46A !important;
      }

      .slide-2 {
        display: block !important;
      }
    }

    .i3-selected {
      .i3 {
        border-color: #E9C46A !important;
        background-color: #E9C46A !important;
      }

      .p3 {
        background-color: #E9C46A !important;
      }

      .slide-3 {
        display: block !important;
      }
    }

    .i4-selected {
      .i4 {
        border-color: #E9C46A !important;
        background-color: #E9C46A !important;
      }

      .p4 {
        background-color: #E9C46A !important;
      }

      .slide-4 {
        display: block !important;
      }
    }

    .slide-container {
      display: flex;
      position: relative;

      .slide-arrow {
        display: flex;
        height: 100%;
        align-items: 'center';
        flex-direction: column;
        padding: 5px;
      }
    }

    .map-slides {
      background: #264653;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 33%;

      .slide-1, .slide-2, .slide-3, .slide-4 {
        display: none;
      }

      

      .slides {
        text-align: center;
        flex: 0;
        min-height: 60px;
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #FFF;
          border-radius: 50%;
          border: 2px solid #FFF;
          margin: 5px;
        } 
      }

      h3 {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 1.5em;
        margin-top: 0px;
      }
    }

  .footer-container {
    color: #264653;
    padding: 20px;
    .grouped {
      @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        &>div {
          padding: 5px !important;
        }
      }
      max-width: 960px;
      margin: auto;
      display: flex;
      &>div {
        padding: 40px;
        line-height: 1.6em;
      }
    }
    .copyright {
      text-align: center;
      font-size: 0.7em;
    }
  }

  .ready-yet {
    padding: 25px;
    box-shadow: inset 0 -15px 20px -20px #000000;
    background-color: #264653;

    & > p {
      max-width: 320px;
      margin:  25px auto;
      text-align: center;
      font-size: 0.8em;
    }
  }
`;

const App = (): React.ReactElement => {
  
  return (
    <StyledApp>
      <div className="header-container">
        <Header />
        <Fade triggerOnce>
          <h1>
            Discover local cannabis&nbsp;
            <strong className="a">deals</strong>,&nbsp;
            <strong className="b">brands</strong>,&nbsp;
            <strong className="c">events</strong>,&nbsp;
            and individuals in <strong className="d">the community</strong>
          </h1>
          <div className="form-notify">
            <FormNotify />
          </div>
          <p className="sub-title">
            Sign up today to get early access.
          </p>
          <div className="grouped">
            <img src={appStoreImg} />
            <img src={playStoreImg} />
          </div>
        </Fade>
        <div className="swipe">
          <img src={swipeImg} />
        </div>
      </div>
      <div className="body-content">
        <div className="party">
          <Fade triggerOnce>
            <h2>Don&rsquo;t just Share Experiences
              <br />
              <strong>Make them</strong>
            </h2>
          </Fade>
          <Fade cascade damping={0} triggerOnce>
            <div className="app-frame">
            </div>
          </Fade>
        </div>
      </div>
      <div className="engage">
      <Fade cascade className="engage-item">
          <div className="engage-copy">
            <h3>Find Great <strong>Deals</strong></h3>
            <p>
              Find great deals to the products you love and explore new and interesting offerings from your local dispenseries.  
            </p>
            <img src={dealsImg} />
          </div>
        </Fade>
        <Fade cascade className="engage-item">
          <div className="engage-copy">
            <h3><strong>Events</strong> near you</h3>
            <p>
              Don&rsquo;t be late to the party. Always be in the know about cannabis events happening near you.
            </p>
            <img src={mapImg} />
          </div>
        </Fade>
        <Fade cascade className="engage-item">
          <div className="engage-copy">
            <h3>Be <strong>The community</strong></h3>
            <p>
              Freely share images and video without worry, we are all cannabis friendly here.
            </p>
            <img src={communityImg} />
          </div>
        </Fade>
      </div>
      {/* <div className={`map-slides-container i${currentMapSlide}-selected`}>
        <div className="map">
          <div className="map-items">
            <div className="item i1 itm-content" style={{
              left: "5%",
              top: "10%",
              width: 300,
              height: "auto",
            }}>
              <div>
                <img src={goodPuraEarthDealImg} />
              </div>
              <div className="copy">
                <div className="title">
                  20% Off Puraearth
                </div>
                <div className="description">
                  June 13th &amp; 27th - Flash Sale on all Puraearth products.
                </div>
              </div>
            </div>
            <div className="item i2 itm-content" style={{
              left: "70%",
              top: "60%",
              width: 60,
              height: 60,
              borderRadius: 45,
              overflow: "hidden",
              padding: 0,
              background: `url(${goodIconImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}>
            </div>
            <div className="item i3 itm-content" style={{
              left: "14%",
              top: "82%",
              width: 40,
              height: 40,
              borderRadius: 45,
              overflow: "hidden",
              padding: 0,
              background: `url(${truckImg})`,
              backgroundColor: "#264653",
              backgroundPosition: "center",
              backgroundSize: "2em",
              backgroundRepeat: "no-repeat",
            }}>
            </div>
            <div className="item i4 itm-content" style={{
              left: "50%",
              top: "50%",
              width: 40,
              height: 40,
              borderRadius: 45,
              overflow: "hidden",
              padding: 0,
              background: `url(${golfIconImg})`,
              backgroundColor: "#264653",
              backgroundPosition: "center",
              backgroundSize: "2em",
              backgroundRepeat: "no-repeat",
            }}></div>
          </div>
        </div>
        <div className="map-slides">
          <div className="slides">
            <span className="pin p1"></span>
            <span className="pin p2"></span>
            <span className="pin p3"></span>
            <span className="pin p4"></span>
          </div>
          <div className="slide-container"> 
            <div className="slide-arrow slide-arrow-left" onClick={() => setMapSlide(currentMapSlide !== 1 ? currentMapSlide - 1 : slideCount)}>
              <img src={arrowLeftImg} />
            </div>
            <div className="slide-content">
              <div className="slide-1">
                <h3>Discover great deals</h3>
                <p>
                  Find the best deals on the things you love and discover new and interesting products to expand your horizon.
                </p>
              </div>
              <div className="slide-2">
                <h3>Discover Great Dispensaries</h3>
                <p>
                  Great service.
                  Great Prices.
                  Exmplery
                </p>
              </div>
              <div className="slide-3">
                <h3>Discover Events</h3>
                <p>Find the best deals on the things you love and discover new and interesting products to expand your horizon.</p>
              </div>
              <div className="slide-4">
                <h3>Discover Deliveries Near You</h3>
                <p>Find the best deals on the things you love and discover new and interesting products to expand your horizon.</p>
              </div>
            </div>
            <div className="slide-arrow slide-arrow-right" onClick={() => setMapSlide(currentMapSlide !== slideCount ? currentMapSlide + 1 : 1)}>
              <img src={arrowRightImg} />
            </div>
          </div>
        </div>
      </div> */}
      <div className="ready-yet">
        <h3>Ready to join the community?</h3>
        <FormNotify backgroundColor="#264653" />
        <p>
          Do not miss out on deals, events, and keep up with the community you love.
        </p>
      </div>
      <div className="footer-container">
        <div className="copyright">Copyright &copy; 2021 Trolly Inc.</div>
      </div>
    </StyledApp>
  );
};

export default App;
