import * as React from "react";
import styled from "styled-components";

import logo from "./logo.svg";

const Header = (): React.ReactElement => {

  const StyledHeader = styled.div`
    height: 100px;

    .logo {
        padding: 25px;
        height: 30px;
        text-align: center;
        margin: auto;
        

        img {
            height: 100%;
        }
    }
  `;

  return (
    <StyledHeader>
        <div className="logo">
          <img src={logo} />
        </div>
    </StyledHeader>
  );
};

export default Header;